.App {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  overflow: hidden;
  .ViewArea, .InputArea {
    width: 46vw;
    height: 95vh;
  }
  .ViewArea {
    padding: 2.5vh 2%;
    overflow-y: scroll;
    pre > code {
      word-break: break-all;
      white-space: break-spaces;
    }
  }
  .InputArea {
    textarea {
      font-size: 18px;
      resize: none;
      border: none;
      outline: none;
      width: 100%;
      height: 95vh;
      padding: 2.5vh 4%;
      overflow-y: scroll;
    }
  }
  .OpArea {
    position: absolute;
    right: 20px;
    bottom: 20px;
    display: flex;
    flex-direction: column;
    button + button {
      margin-top: 10px;
    }
  }
}

.Mobile {
  flex-direction: column;
  & > .ViewArea, & > .InputArea {
    width: 95vw;
    height: 46vh;
  }
  & > .ViewArea {
    padding: 2vh 2.5vw;
    overflow-y: scroll;
    pre > code {
      word-break: break-all;
      white-space: break-spaces;
    }
  }
  & > .InputArea {
    textarea {
      font-size: 18px;
      resize: none;
      border: none;
      outline: none;
      width: 95vw;
      height: 46vh;
      padding: 2vh 2.5vw;
      overflow-y: scroll;
    }
  }
}
